<template>
  <div>
    <div class= "col-md-12">
      <CCard>
        <CCardHeader>
          <strong>Temperature (°C)</strong>
        </CCardHeader>
        <CChartLine
          v-if="this.fetch"
          :datasets="defaultDatasets(temperature)"
          :options="defaultOptions(temperature)"
          :labels="y1_label"
          style="height: 300px; margin-top: 40px"
      />

      </CCard>
    </div>
  </div>
</template>


<script>
import MainChartExample from "../../charts/GraphChartExample";
export default {
  name: 'AdminGraphDetails',
   components: { MainChartExample },    
}
</script>