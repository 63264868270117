<template>
    <CChartLine
        :datasets="defaultDatasets"
        :options="defaultOptions"
        :labels="getLabels"
    />
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils/src";

export default {
    name: "MainChartExample",
    components: {
        CChartLine,
    },
    props: {
        sensorData: Array,
        sensorType: String,
    },
    data() {
        return {
            label: [],
        };
    },
    computed: {
        getLabels() {
          // console.log(this.sensorData[0]['date']);           
          // console.log(this.sensorType);
            // for (let i = 0; i <= this.sensorData.length; i++) {
            //     this.label.push(this.sensorData[i].date);
            // }
            console.log(this.label)
            return this.sensorData;
        },
        defaultDatasets() {
          console.log(this.sensorData)
            const brandSuccess = getStyle("success2") || "#4dbd74";
            const brandInfo = getStyle("info") || "#20a8d8";

            const data1 = [];

            // for (let i = 0; i <= this.sensorData.length; i++) {
            //     data1.push(this.sensorData[i][this.sensorType]);
            // }

            return [
                {
                    label: "Value: ",
                    backgroundColor: hexToRgba(brandInfo, 10),
                    borderColor: brandSuccess,
                    pointHoverBackgroundColor: brandSuccess,
                    borderWidth: 2,
                    data: data1,
                },
            ];
        },
        
        defaultOptions() {
            return {
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                drawOnChartArea: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                stepSize: Math.ceil(10 / 5),
                                max: 10,
                            },
                            gridLines: {
                                display: true,
                            },
                        },
                    ],
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            };
        },
    },
};
</script>
